/* eslint-disable no-use-before-define */
import { Injectable } from '@angular/core';
import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
    Store,
} from '@ngxs/store';
import { subBusinessDays } from 'date-fns';
import {
    clone,
} from 'ramda';
import {
    forkJoin,
} from 'rxjs';
import {
    tap,
} from 'rxjs/operators';

import {
    SHARE_DETAIL_DEFAULT_PERIOD,
    SHARE_DETAILS_VIEW_MODES_DEFAULT_VALUES,
} from '~/app/core/constants/share-detail.constants';
import { ActiveRisksService } from '~/app/core/services/api/active-risks/active-risks.service';
import { NotationsService } from '~/app/core/services/api/notations/notations.service';
import { RiskIndicatorsService } from '~/app/core/services/api/risk-indicators/risk-indicators.service';
import { SharesService } from '~/app/core/services/api/shares/shares.service';
import {
    SharesListState,
    SharesListStateModel,
} from '~/app/core/state/shares-list/shares-list.state';
import { Benchmark } from '~/app/shared/enums/benchmark.enum';
import { CalendarPerformanceType } from '~/app/shared/enums/calendar-performance-type.enum';
import { ContributionModeType } from '~/app/shared/enums/contribution-mode-type.enum';
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { ShareViewModeValue } from '~/app/shared/enums/share-view-mode-value.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import {
    ActiveRisk,
    Notation,
    RiskIndicator,
} from '~/app/shared/interfaces/RiskIndicator';
import { IndicatorsUtilsService } from '~/app/shared/services/indicators-utils/indicators-utils.service';
import { Collection } from '~/app/shared/types/collection.type';
import { ComparedEntity } from '~/app/shared/types/compared-entity.type';
import { ShareCategory } from '~/app/shared/types/shares/share-category.type';
import { ShareDetailParams } from '~/app/shared/types/shares/share-detail-params/share-detail-params.type';
import { ShareDetails } from '~/app/shared/types/shares/share-details.type';
import { Share } from '~/app/shared/types/shares/share.type';

import {
    ChangeViewModeAction,
    GetActiveRisksValueForShareDetailAction,
    GetCompatibleCategoriesAction,
    GetIndicatorsAction,
    GetNotationsValueForShareDetailAction,
    GetOtherSharesAction,
    GetRiskIndicatorsValueForShareDetailAction,
    GetShareDetailsAction,
    GetSimilarSharesAction,
    InitShareDetailsAction,
    ResetViewModeDisplayAction,
    SetComparedEntitiesAction,
    SetViewModeDisplayValuesAction,
    UpdateShareDetailsCalendarPerfParams,
    UpdateShareDetailsMarketCycleParamsAction,
    UpdateShareDetailsRollingPeriodParams,
} from './share-details.actions';
import { ColorUtilsService } from '../../utils/color-utils/color-utils.service';

export interface ShareDetailsStateModel {
    shareDetails: ShareDetails | null,
    comparedEntities: Array<ComparedEntity>,
    notations: { [key: string] : Array<Notation> },
    riskIndicators: { [key: string] : Array<RiskIndicator> },
    isLoadingRiskIndicator: boolean,
    activeRisks: { [key: string] : Array<ActiveRisk> },
    similarShares: Collection<Share>,
    otherShares: Collection<Share>,
    compatibleCategories: ShareCategory[],
    currentVideoCategory: string,
    currentDocumentCategory: string,
    viewModeDisplay: {
        [key: string]: ShareViewModeValue[],
    },
    params: ShareDetailParams,
}


const defaults = {
    shareDetails: null,
    comparedEntities: [],
    notations: {},
    riskIndicators: {},
    isLoadingRiskIndicator: true,
    activeRisks: {},
    similarShares: {
        size: 20,
        totalNumber: 0,
        startAt: 0,
        values: [],
    },
    otherShares: {
        size: 20,
        totalNumber: 0,
        startAt: 0,
        values: [],
    },
    compatibleCategories: [],
    currentVideoCategory: 'all',
    currentDocumentCategory: 'all',
    viewModeDisplay: {
        PRO: SHARE_DETAILS_VIEW_MODES_DEFAULT_VALUES.PRO,
        MAIN: SHARE_DETAILS_VIEW_MODES_DEFAULT_VALUES.MAIN,
        ADVANCED: SHARE_DETAILS_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
        SHARED: [],
    },
    params: {
        viewMode: ViewModeType.MAIN,
        rollingPeriod: {
            period: SHARE_DETAIL_DEFAULT_PERIOD,
            endDate: subBusinessDays(new Date(), 1).toISOString(),
            riskIndicatorsViewMode: ViewModeType.MAIN,
        },
        calendarPerf: {
            type: CalendarPerformanceType.YEARS,
        },
        marketCycle: {
            benchmark: Benchmark.BCAT,
            contributionMode: ContributionModeType.STANDARD,
        },
    },
};

@State<ShareDetailsStateModel>({
    name: 'shareDetails',
    defaults,
})

@Injectable()
export class ShareDetailsState {
    constructor(
        private shareDetailsService: SharesService,
        private riskIndicatorsService: RiskIndicatorsService,
        private notationsService: NotationsService,
        private activeRisksService: ActiveRisksService,
        private store: Store,
        private indicatorsUtilsService: IndicatorsUtilsService,
        private colorsUtilsService: ColorUtilsService,
    ) {}

    static isDisplayedInCurrentViewMode(viewModeValue: ShareViewModeValue) {
        // eslint-disable-next-line max-len
        return createSelector([ShareDetailsState], (state: ShareDetailsStateModel) => state.viewModeDisplay[state.params.viewMode].some((item) => item === viewModeValue));
    }

    @Selector()
    static getComparedEntities(state: ShareDetailsStateModel): ComparedEntity[] {
        return state.comparedEntities;
    }

    @Selector()
    static canAddComparedEntities(state: ShareDetailsStateModel) {
        return state.comparedEntities.length < 3;
    }

    @Selector()
    static getShareDetailsHeaderInformation(state: ShareDetailsStateModel) {
        return {
            id: state.shareDetails?.id,
            name: state.shareDetails?.name,
            srri: state.shareDetails?.srri,
            subCategory: state.shareDetails?.subCategory,
            logo: state.shareDetails?.assetManagerCompany.logo,
            isAlive: state.shareDetails?.isAlive,
            isSfdrArticle8: state.shareDetails?.isSfdrArticle8,
            isSfdrArticle9: state.shareDetails?.isSfdrArticle9,
            isSri: state.shareDetails?.isSri,
            isGreenfin: state.shareDetails?.isGreenfin,
            isPartner: state.shareDetails?.isPartner,
            isin: state.shareDetails?.isin,
        };
    }

    @Selector()
    static getShareDetails(state: ShareDetailsStateModel) {
        return state.shareDetails;
    }

    @Selector()
    static subCategoryId(state: ShareDetailsStateModel) {
        return state.shareDetails?.subCategoryId;
    }

    @Selector()
    static getShareDetailsLaunchDate(state: ShareDetailsStateModel) {
        return state.shareDetails?.launchDate;
    }

    @Selector()
    static getShareDetailsFirstCleanDate(state: ShareDetailsStateModel): string | undefined {
        return state.shareDetails?.firstCleanDate;
    }

    @Selector()
    static getShareDetailsPriceDate(state: ShareDetailsStateModel) {
        return state.shareDetails?.priceDate;
    }

    @Selector()
    static getShareDetailsInformation(state: ShareDetailsStateModel) {
        return {
            name: state.shareDetails?.assetManagerCompany?.name,
            investmentObjective: state.shareDetails?.investmentObjective,
            comment: state.shareDetails?.comment,
            isPea: state.shareDetails?.isPea,
            srri: state.shareDetails?.srri,
            benchmark: state.shareDetails?.benchmark,
            fundCurrency: state.shareDetails?.fundCurrency,
            currency: state.shareDetails?.currency,
            isCurrencyHedge: state.shareDetails?.isCurrencyHedge,
            priceType: state.shareDetails?.priceType,
            price: state.shareDetails?.price,
            priceDate: state.shareDetails?.priceDate,
            minimumInitialAmount: state.shareDetails?.minimumInitialAmount,
            minimumNextAmount: state.shareDetails?.minimumNextAmount,
            investorType: state.shareDetails?.investorType,
            ongoingChargeFee: state.shareDetails?.ongoingChargeFee,
            extryFee: state.shareDetails?.extryFee,
            exitFee: state.shareDetails?.exitFee,
            umbrella: state.shareDetails?.umbrella,
            firstCleanDate: state.shareDetails?.firstCleanDate,
            fundLaunchDate: state.shareDetails?.fundLaunchDate,
            launchDate: state.shareDetails?.launchDate,
            domicile: state.shareDetails?.domicile,
            distributionCountry: state.shareDetails?.distributionCountry,
            subCategoryId: state.shareDetails?.subCategoryId,
        };
    }

    @Selector()
    static getShareDetailsManagers(state: ShareDetailsStateModel) {
        return state.shareDetails?.fundManagers;
    }

    @Selector()
    static getShareDetailsFundDocuments(state: ShareDetailsStateModel) {
        return state.shareDetails?.fundDocuments;
    }

    @Selector()
    static getShareDetailsAssuranceVies(state: ShareDetailsStateModel) {
        return state.shareDetails?.assuranceVies;
    }

    @Selector()
    static getShareDetailsVideos(state: ShareDetailsStateModel) {
        return state.shareDetails?.videos;
    }

    @Selector()
    static getShareDetailsDocuments(state: ShareDetailsStateModel) {
        return state.shareDetails?.documents;
    }

    @Selector()
    static getShareDetailsId(state: ShareDetailsStateModel) {
        return state.shareDetails?.id;
    }

    @Selector()
    static getShareDetailsCategoryId(state: ShareDetailsStateModel) {
        return state.shareDetails?.categoryId;
    }

    @Selector()
    static getShareDetailsSubCategoryId(state: ShareDetailsStateModel) {
        return state.shareDetails?.subCategoryId;
    }

    @Selector()
    static getShareDetailsFundId(state: ShareDetailsStateModel) {
        return state.shareDetails?.fundId;
    }

    @Selector()
    static getShareDetailsAllNotations(state: ShareDetailsStateModel) {
        return state.notations;
    }

    @Selector()
    static getShareDetailsAllRiskIndicators(state: ShareDetailsStateModel) {
        return state.riskIndicators;
    }

    @Selector()
    static isLoadingRiskIndicator(state: ShareDetailsStateModel) {
        return state.isLoadingRiskIndicator;
    }

    @Selector([ShareDetailsState.getShareDetailsId])
    static getShareDetailsRiskIndicators(state: ShareDetailsStateModel, shareDetailId: number) {
        return state.riskIndicators[shareDetailId] ?? [];
    }

    @Selector([ShareDetailsState.getShareDetailsId])
    static getShareDetailsNotations(state: ShareDetailsStateModel, shareDetailId: number) {
        return state.notations[shareDetailId] ?? [];
    }

    @Selector()
    static getShareDetailsAllActiveRisks(state: ShareDetailsStateModel) {
        return state.activeRisks;
    }

    @Selector()
    static getShareDetailsParams(state: ShareDetailsStateModel) {
        return state.params;
    }

    @Selector()
    static getShareDetailsViewModeParams(state: ShareDetailsStateModel): ViewModeType {
        return state.params.viewMode;
    }

    @Selector()
    static getShareDetailsViewModeDisplay(state: ShareDetailsStateModel) {
        return state.viewModeDisplay[state.params.viewMode];
    }

    @Selector()
    static getShareDetailsRollingPeriodParams(state: ShareDetailsStateModel) {
        return state.params.rollingPeriod;
    }

    @Selector()
    static getShareDetailsRollingPeriodEndDateParams(state: ShareDetailsStateModel) {
        return state.params.rollingPeriod.endDate;
    }

    @Selector()
    static getShareDetailsCalendarPerfParams(state: ShareDetailsStateModel) {
        return state.params.calendarPerf;
    }

    @Selector()
    static getShareDetailsMarketCycleParams(state: ShareDetailsStateModel) {
        return state.params.marketCycle;
    }

    @Selector()
    static getShareDetailsMarketCycleBenchmarkParams(state: ShareDetailsStateModel): Benchmark {
        return state.params.marketCycle.benchmark;
    }

    @Selector()
    static compatibleCategories(state: ShareDetailsStateModel) {
        return state.compatibleCategories;
    }

    @Selector()
    static similarShares(state: ShareDetailsStateModel) {
        return state.similarShares.values;
    }

    @Selector()
    static totalNumberOfSimilarShares(state: ShareDetailsStateModel) {
        return state.similarShares.totalNumber;
    }

    @Selector()
    static similarSharesLength(state: ShareDetailsStateModel) {
        return state.similarShares.values?.length;
    }

    @Selector()
    static otherShares(state: ShareDetailsStateModel) {
        return state.otherShares.values;
    }

    @Selector()
    static totalNumberOfOtherShares(state: ShareDetailsStateModel) {
        return state.otherShares.totalNumber;
    }

    @Selector()
    static otherSharesLength(state: ShareDetailsStateModel) {
        return state.otherShares.values?.length;
    }

    @Action(InitShareDetailsAction)
    initShareDetails({ patchState }: StateContext<ShareDetailsStateModel>) {
        patchState({
            notations: {},
            riskIndicators: {},
            isLoadingRiskIndicator: true,
            activeRisks: {},
            similarShares: {
                size: 20,
                totalNumber: 0,
                startAt: 0,
                values: [],
            },
            otherShares: {
                size: 20,
                totalNumber: 0,
                startAt: 0,
                values: [],
            },
            compatibleCategories: [],
            currentVideoCategory: 'all',
            currentDocumentCategory: 'all',
        });
    }

    @Action(GetShareDetailsAction)
    getShareDetails({ getState, patchState }: StateContext<ShareDetailsStateModel>, { id, shareParams }: GetShareDetailsAction) {
        return this.shareDetailsService.getShareDetails(id).pipe(tap((result) => {
            const state = getState();
            const colors = this.colorsUtilsService.getThemeColors(2, 0);

            patchState({
                shareDetails: result,
                comparedEntities: [
                    {
                        id: result.id,
                        name: result?.name || result.assetManagerCompany?.name,
                        color: colors[0],
                        type: EntityType.SHARE,
                        isReference: true,
                    },
                    ...(
                        !shareParams && result?.subCategory && result?.subCategoryId
                            ? [{
                                id: result.subCategoryId,
                                name: result.subCategory,
                                color: colors[1],
                                type: EntityType.CATEGORY,
                                isReference: false,
                            }]
                            : []
                    ),
                    ...(shareParams?.comparedEntities || []).filter((item) => !item.isReference),
                ],
                viewModeDisplay: {
                    ...state.viewModeDisplay,
                    SHARED: shareParams?.display || [],
                },
                params: {
                    ...state.params,
                    ...(shareParams?.params || {}),
                    rollingPeriod: {
                        ...defaults.params.rollingPeriod,
                        ...(result.priceDate ? { endDate: new Date(result.priceDate).toISOString() } : {}),
                        ...(shareParams?.params.rollingPeriod || {}),
                    },
                },

            });
        }));
    }

    @Action(GetSimilarSharesAction)
    getSimilarShares({ getState, patchState, dispatch }: StateContext<ShareDetailsStateModel>, { id, reset }: GetSimilarSharesAction) {
        if (reset) {
            patchState({
                similarShares: {
                    size: 20,
                    totalNumber: 0,
                    startAt: 0,
                    values: [],
                },
            });
        }
        const currentState = getState();
        return this.shareDetailsService.getSimilarShares(id, {
            startAt: currentState.similarShares.startAt,
            size: 20,
        }).pipe(tap((result) => {
            const state = getState();
            patchState({
                similarShares: {
                    ...state.similarShares,
                    totalNumber: result.totalNumber,
                    startAt: result.values[result.values.length - 1]?.id,
                    values: [
                        ...(state.similarShares.values ?? []),
                        ...result.values,
                    ],
                },
            });
        }),
        tap((result) => dispatch(new GetIndicatorsAction(result.values.map((share) => share.id), 'similarShares'))));
    }

    @Action(GetOtherSharesAction)
    getOtherShares({ getState, patchState, dispatch }: StateContext<ShareDetailsStateModel>, { id, reset }: GetOtherSharesAction) {
        if (reset) {
            patchState({
                otherShares: {
                    size: 20,
                    totalNumber: 0,
                    startAt: 0,
                    values: [],
                },
            });
        }
        const currentState = getState();
        return this.shareDetailsService.getOtherShares(id, {
            startAt: currentState.otherShares.startAt,
            size: 20,
        }).pipe(tap((result) => {
            const state = getState();
            patchState({
                otherShares: {
                    ...state.otherShares,
                    totalNumber: result.totalNumber,
                    startAt: result.values[result.values.length - 1]?.id,
                    values: [
                        ...(state.otherShares.values ?? []),
                        ...result.values.filter((share) => share.id !== state.shareDetails?.id),
                    ],
                },
            });
        }),
        tap((result) => dispatch(new GetIndicatorsAction(result.values.map((share) => share.id), 'otherShares'))));
    }

    @Action(GetIndicatorsAction)
    getIndicatorsAction({ getState, patchState }: StateContext<SharesListStateModel>, action: GetIndicatorsAction) {
        const displayIndicator = this.store.selectSnapshot(SharesListState.getDisplayIndicatorShare);
        const indicatorsParams = this.indicatorsUtilsService.createIndicatorsForRequests(displayIndicator);
        return forkJoin([
            this.riskIndicatorsService.getRiskIndicatorsValuesForSharesOrCategories(action.sharesId, [], indicatorsParams.defaultRiskIndicators),
            this.activeRisksService.getActiveRisksValuesForSharesOrCategories(action.sharesId, [], indicatorsParams.defaultActiveRisks),
            this.notationsService.getNotationsValuesForSharesOrCategories(action.sharesId, [], indicatorsParams.defaultNotations),
        ]).pipe(
            tap(([resultRiskIndicators, resultActiveRisks, resultNotations]) => {
                const state = getState();
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const shares = clone(state[action.nameField].values as Array<Share>);
                let shareIndex: number;
                resultRiskIndicators.forEach((riskIndicator) => {
                    if (riskIndicator.type === EntityType.SHARE) {
                        shareIndex = shares.findIndex((share) => share.id === riskIndicator.id);
                        if (shareIndex !== -1) {
                            shares[shareIndex].riskIndicators = riskIndicator.riskIndicators;
                        }
                    }
                });

                resultActiveRisks.forEach((activeRisk) => {
                    if (activeRisk.type === EntityType.SHARE) {
                        shareIndex = shares.findIndex((share) => share.id === activeRisk.id);
                        if (shareIndex !== -1) {
                            shares[shareIndex].activeRisks = activeRisk.activeRisks;
                        }
                    }
                });

                resultNotations.forEach((notation) => {
                    if (notation.type === EntityType.SHARE) {
                        shareIndex = shares.findIndex((share) => share.id === notation.id);
                        if (shareIndex !== -1) {
                            shares[shareIndex].notations = notation.notations;
                        }
                    }
                });

                // @ts-ignore
                patchState({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    [action.nameField]: {
                        // @ts-ignore
                        ...state[action.nameField],
                        values: shares,
                    },
                });
            }),
        );
    }

    @Action(GetCompatibleCategoriesAction)
    getCompatibleCategories({ patchState }: StateContext<ShareDetailsStateModel>, { id }: GetCompatibleCategoriesAction) {
        return this.shareDetailsService.getCompatibleCategories(id).pipe(tap((result) => {
            patchState({
                compatibleCategories: [...result],
            });
        }));
    }

    @Action(GetNotationsValueForShareDetailAction)
    getNotationsValueForShareDetail({ getState, patchState }: StateContext<ShareDetailsStateModel>,
        { shareIds, categoryIds, notationsParam }: GetNotationsValueForShareDetailAction) {
        return this.notationsService.getNotationsValuesForSharesOrCategories(shareIds, categoryIds, notationsParam).pipe(tap((result) => {
            const state = getState();
            const notations = clone(state.notations);

            for (let i = 0; i < result.length; i += 1) {
                notations[result[i].id] = [
                    ...result[i]?.notations,
                    ...(notations[result[i].id] ?? []),
                ].reduce((acc: Notation[], notation: Notation) => {
                    if (!acc.find((ind) => ind.benchmark === notation.benchmark && ind.code === notation.code)) {
                        acc.push(notation);
                    }
                    return acc;
                }, []);
            }

            patchState({
                notations,
            });
        }));
    }

    @Action(GetRiskIndicatorsValueForShareDetailAction)
    getRiskIndicatorsValueForShareDetailAction({ getState, patchState }: StateContext<ShareDetailsStateModel>,
        { shareIds, categoryIds, riskIndicatorsParam, changeIsLoading, endDate }: GetRiskIndicatorsValueForShareDetailAction) {
        if (changeIsLoading) {
            patchState({
                isLoadingRiskIndicator: true,
            });
        }
        return this.riskIndicatorsService.getRiskIndicatorsValuesForSharesOrCategories(shareIds, categoryIds, riskIndicatorsParam, endDate)
            .pipe(tap((result) => {
                const state = getState();
                const riskIndicators = clone(state.riskIndicators);

                for (let i = 0; i < result.length; i += 1) {
                    riskIndicators[result[i].id] = [
                        ...result[i]?.riskIndicators.map((riskIndicator) => ({
                            ...riskIndicator,
                            ...(endDate ? { endDate } : {}),
                        })),
                        ...(riskIndicators[result[i].id] ?? []),
                    ].reduce((acc: RiskIndicator[], riskIndicator: RiskIndicator) => {
                        if (!acc.find((ind) => ind.period === riskIndicator.period
                            && ind.endDate === riskIndicator.endDate
                            && ind.code === riskIndicator.code)
                        ) {
                            acc.push(riskIndicator);
                        }
                        return acc;
                    }, []);
                }

                patchState({
                    riskIndicators,
                    ...(changeIsLoading ? { isLoadingRiskIndicator: false } : {}),
                });
            }));
    }

    @Action(GetActiveRisksValueForShareDetailAction)
    getActiveRisksValueForShareDetailAction({ getState, patchState }: StateContext<ShareDetailsStateModel>,
        { shareIds, categoryIds, activeRisksParam, endDate }: GetActiveRisksValueForShareDetailAction) {
        return this.activeRisksService.getActiveRisksValuesForSharesOrCategories(shareIds, categoryIds, activeRisksParam, endDate)
            .pipe(tap((result) => {
                const state = getState();
                const activeRisks = clone(state.activeRisks);

                for (let i = 0; i < result.length; i += 1) {
                    activeRisks[result[i].id] = [
                        ...result[i]?.activeRisks.map((activeRisk) => ({
                            ...activeRisk,
                            ...(endDate ? { endDate } : {}),
                        })),
                        ...(activeRisks[result[i].id] ?? []),
                    ].reduce((acc: ActiveRisk[], activeRisk: ActiveRisk) => {
                        if (!acc.find((ind) => ind.period === activeRisk.period
                            && ind.code === activeRisk.code
                            && ind.endDate === activeRisk.endDate
                            && ind.benchmark === activeRisk.benchmark)
                        ) {
                            acc.push(activeRisk);
                        }
                        return acc;
                    }, []);
                }

                patchState({
                    activeRisks,
                });
            }));
    }

    @Action(UpdateShareDetailsRollingPeriodParams)
    updateShareDetailsRollingPeriodParams({ getState, patchState }: StateContext<ShareDetailsStateModel>,
        { period, endDate, riskIndicatorsViewMode }: UpdateShareDetailsRollingPeriodParams) {
        const state = getState();

        patchState({
            params: {
                ...state.params,
                rollingPeriod: {
                    period,
                    endDate,
                    riskIndicatorsViewMode,
                },
            },
        });
    }

    @Action(UpdateShareDetailsCalendarPerfParams)
    updateShareDetailsCalendarPerfParams({ getState, patchState }: StateContext<ShareDetailsStateModel>,
        { type }: UpdateShareDetailsCalendarPerfParams) {
        const state = getState();

        patchState({
            params: {
                ...state.params,
                calendarPerf: {
                    type,
                },
            },
        });
    }

    @Action(UpdateShareDetailsMarketCycleParamsAction)
    updateShareDetailsMarketCycleParams({ getState, patchState }: StateContext<ShareDetailsStateModel>,
        { benchmark, contributionMode }: UpdateShareDetailsMarketCycleParamsAction) {
        const state = getState();

        patchState({
            params: {
                ...state.params,
                marketCycle: {
                    benchmark,
                    contributionMode,
                },
            },
        });
    }

    @Action(SetComparedEntitiesAction)
    setComparedEntities({ getState, patchState }: StateContext<ShareDetailsStateModel>, action: SetComparedEntitiesAction) {
        const state = getState();
        const colors = this.colorsUtilsService.getThemeColors(1, 0);
        patchState({
            comparedEntities: [
                {
                    id: state.shareDetails?.id || 0,
                    name: state.shareDetails?.name || state.shareDetails?.assetManagerCompany.name || '',
                    color: colors[0],
                    type: EntityType.SHARE,
                    isReference: true,
                },
                ...action.entities,
            ],
        });
    }

    @Action(SetViewModeDisplayValuesAction)
    setViewModeDisplayValuesAction({ getState, patchState }: StateContext<ShareDetailsStateModel>, action: SetViewModeDisplayValuesAction) {
        const state = getState();
        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                [state.params.viewMode]: action.display,
            },
        });
    }

    @Action(ChangeViewModeAction)
    changeViewMode({ getState, patchState }: StateContext<ShareDetailsStateModel>, action: ChangeViewModeAction) {
        const state = getState();
        patchState({
            params: {
                ...state.params,
                viewMode: action.viewMode,
            },
        });
    }

    @Action(ResetViewModeDisplayAction)
    resetViewModeDisplay({ getState, patchState }: StateContext<ShareDetailsStateModel>) {
        const state = getState();

        const currentViewMode = state.params.viewMode;

        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                ...(
                    currentViewMode === ViewModeType.PRO
                        ? {
                            PRO: SHARE_DETAILS_VIEW_MODES_DEFAULT_VALUES.PRO,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.ADVANCED
                        ? {
                            ADVANCED: SHARE_DETAILS_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.MAIN
                        ? {
                            MAIN: SHARE_DETAILS_VIEW_MODES_DEFAULT_VALUES.MAIN,
                        }
                        : {}
                ),
            },
        });
    }
}
